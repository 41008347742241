<!--导航栏组件-->
<template>
  <div v-if="imgVal" class="container"  :style="{ backgroundImage: `url(${imgVal})` }">
<!--    <img :src="banner[0].src" alt="" style="width:0;height:0">-->
<!--    <div>{{banner[0].src}}</div>-->
    <div class="navP">
      <div class="NavBox">
        <div class="NavBoxLeft">
          <div class="logoTitle">
            <img class="freewareLogo1" src="../../../assets/image/freeSoftware/freewareLogo1.png" alt="">
          </div>
          <div class="NavList">
            <router-link
                :to="{ path: '/' }"
                class="NavItem  c_p"
                :class="{ active: '/' == activeMenu }"
            >首页
            </router-link>
            <router-link
                :to="{ path: '/course' }"
                class="NavItem  c_p"
                :class="{ active: '/course' == activeMenu }"
                target="_blank"
                v-if="activeMenu == '/course'"
            >课程
            </router-link>
            <el-dropdown v-else>
              <router-link
                  target="_blank"
                  :to="{ path: '/course' }"
                  class="NavItem  c_p"
                  :class="{ active: '/course' == activeMenu }"
              >课程
              </router-link>
              <el-dropdown-menu slot="dropdown" class="Dropdown">
                <CourseComeDown link="/course/index"></CourseComeDown>
              </el-dropdown-menu>
            </el-dropdown>
            <router-link
                target="_blank"
                :to="{ path: '/su' }"
                class="NavItem  c_p"
                :class="{ active: '/su' == activeMenu }"
                v-if="'/su' == activeMenu"
            >SU模型
            </router-link>
            <el-dropdown v-else>
              <router-link
                  target="_blank"
                  :to="{ path: '/su' }"
                  class="NavItem  c_p"
                  :class="{ active: '/su' == activeMenu }"
              >SU模型
                <!--              <img src="../../assets/image/icon/hot-home.png" alt="" />-->
              </router-link>
              <el-dropdown-menu slot="dropdown" class="Dropdown">
                <SuComeDown link="/su/index"></SuComeDown>
              </el-dropdown-menu>
            </el-dropdown>
            <router-link
                target="_blank"
                :to="{ path: '/max' }"
                class="NavItem  c_p"
                :class="{ active: '/max' == activeMenu }"
                v-if="'/max' == activeMenu"
            >3D模型
            </router-link>
            <el-dropdown v-else>
              <router-link
                  target="_blank"
                  :to="{ path: '/max' }"
                  class="NavItem  c_p"
                  :class="{ active: '/max' == activeMenu }"
              >3D模型
                <!-- <img src="../../../assets/image/icon/new-home.png" alt="" /> -->
              </router-link>
              <el-dropdown-menu slot="dropdown" class="Dropdown">
                <MaxComeDown link="/max/index"></MaxComeDown>
              </el-dropdown-menu>
            </el-dropdown>
            <router-link
                target="_blank"
                :to="{ path: '/ps' }"
                class="NavItem  c_p"
                :class="{ active: '/ps' == activeMenu }"
                v-if="'/ps' == activeMenu"
            >PS素材
            </router-link>
            <el-dropdown v-else>
              <router-link
                  target="_blank"
                  :to="{ path: '/ps' }"
                  class="NavItem  c_p"
                  :class="{ active: '/ps' == activeMenu }"
              >PS素材
                <!--              <img src="../../assets/image/icon/new-home.png" alt="" />-->
              </router-link>
              <el-dropdown-menu slot="dropdown" class="Dropdown">
                <PsComeDown link="/ps/index"></PsComeDown>
              </el-dropdown-menu>
            </el-dropdown>
            <router-link
                target="_blank"
                :to="{ path: '/schemeText' }"
                class="NavItem  c_p"
                :class="{ active: '/schemeText' == activeMenu }"
                v-if="'/schemeText' == activeMenu"
            >方案文本
            </router-link>
            <el-dropdown v-else>
              <router-link
                  target="_blank"
                  :to="{ path: '/schemeText' }"
                  class="NavItem mr40 c_p"
                  :class="{ active: '/schemeText' == activeMenu }"
              >方案文本
                <!-- <img src="../../assets/image/icon/new-home.png" alt="" /> -->
              </router-link>
							<el-dropdown-menu slot="dropdown" class="Dropdown">
								<SchemeTextComeDown link="/schemeText/index"></SchemeTextComeDown>
							</el-dropdown-menu>
            </el-dropdown>
            <router-link
                target="_blank"
                :to="{ path: '/material' }"
                class="NavItem  c_p"
                :class="{ active: '/material' == activeMenu }"
                v-if="'/material' == activeMenu"
            >资料库
            </router-link>
            <el-dropdown v-else>
              <router-link
                  target="_blank"
                  :to="{ path: '/material' }"
                  class="NavItem  c_p"
                  :class="{ active: '/material' == activeMenu }"
              >资料库
                <img src="../../../assets/image/icon/heji.png" alt="" />
              </router-link>
              <el-dropdown-menu slot="dropdown" class="Dropdown">
                <MaterialComeDown link="/material/index"></MaterialComeDown>
              </el-dropdown-menu>
            </el-dropdown>
						<router-link
							target="_blank"
							:to="{ path: '/chartlet/typeChartlet' }"
							class="NavItem mr40 c_p"
							:class="{ active: '/chartlet' == activeMenu }"
							v-if="'/chartlet' == activeMenu"
							>贴图
							<img style="top: 0.08rem;" src="@/assets/image/icon/new-home.png" alt="" />
						</router-link>
						<el-dropdown v-else>
							<router-link
								target="_blank"
								:to="{ path: '/chartlet/typeChartlet' }"
								class="NavItem mr40 c_p"
								:class="{ active: '/chartlet' == activeMenu }"
								>贴图
								<img src="@/assets/image/icon/new-home.png" alt="" />
							</router-link>
							<el-dropdown-menu slot="dropdown" class="Dropdown">
								<ChartletComeDown link="/chartlet/index"></ChartletComeDown>
							</el-dropdown-menu>
						</el-dropdown>
            <router-link
                target="_blank"
                :to="{ path: '/photo/typeIndex' }"
                class="NavItem  c_p"
                :class="{ active: '/photo' == activeMenu }"
                v-if="'/photo' == activeMenu"
            >图库
            </router-link>

            <el-dropdown v-else>
              <router-link
                  target="_blank"
                  :to="{ path: '/photo/typeIndex' }"
                  class="NavItem  c_p"
                  :class="{ active: '/photo/index' == activeMenu }"
              >图库
              </router-link>
              <el-dropdown-menu slot="dropdown" class="Dropdown">
                <PhotoComeDown link="/photo/index"></PhotoComeDown>
              </el-dropdown-menu>
            </el-dropdown>
            <div>
              <router-link
                  target="_blank"
                  :to="{ path: '/software' }"
                  class="NavItem  c_p"
                  :class="{ active: '/software' == activeMenu }"
                  v-if="'/software' == activeMenu"
              >免费软件
                <!-- <img src="../../../assets/image/icon/new-home.png" alt="" /> -->
              </router-link>
            </div>
						<div>
							<router-link
								target="_blank"
								:to="{ path: '/competition' }"
								class="NavItem c_p"
								:class="{ active: '/competition' == activeMenu }"
								>设计大赛
									<img src="../../../assets/image/icon/new-home.png" alt="" />
							</router-link>
						</div>
            <!-- <a href="http://www.landbd.net/" class="NavItem  c_p">特训营</a> -->
          </div>
        </div>
        <div class="NavUserBox">
          <div style="position: relative" v-if="userInfo.can_upload_software === 1">
            <div class="NavUser  c_p rightBox">
              <img src="@/assets/image/freeSoftware/uploadSoftware.png" alt="" @click="toUploadSoftware"/>
            </div>
          </div>
          <div class="User">
            <router-link v-if="isLogin" :to="{ path: '/personalCenter' }">
              <img :src="userInfo.avatar ? userInfo.avatar : defaultAvatar" alt="" class="NavUserAvatar" />
            </router-link>
            <div v-else class="f16 c_ff c_p" @click="goLogin">登录/注册</div>
            <Collapse>
              <div class="My" v-if="isLogin">
                <div class="ZhiBi">
                  <p>我的知币</p>
                  <div class="Gold">
                    <img src="@/assets/image/icon/gold.png" alt="" />
                    <p>{{ userInfo.zhiCoin | changeW }}</p>
                  </div>
                </div>
                <div class="MyHandle">
                  <router-link :to="{ path: '/personalCenter' }">
                    <p>个人中心</p>
                  </router-link>
                  <p @click="removeToken()">退出登录</p>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
      <div class="searchBox">
        <el-input
            v-model.trim="params.key"
            placeholder="请输入需要查找的软件"
            @keyup.enter.native="searchKey"
            clearable
        ></el-input>
        <i class="iconfont f20 c_99 c_p" style="font-size: 28px !important" @click="searchKey">&#xe67d;</i>
      </div>
			<div class="hot m_c">
				<ul class="hotList">
					<li class="hotLi">热门搜索：</li>
					<li class="hotLi c_p" v-for="item in hotTags" @click="searchTag(item.url)" :key="item.id">
						{{item.title}}
					</li>
				</ul>
			</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getToken, removeToken, setToken } from "../../../utils/auth";
import { latestAnnouncement, getList, getRecommendHotWords } from "../../../Api";
import Collapse from "@/components/collapse";
import CourseComeDown from "@/components/navComeDown/CourseComeDown";
import SuComeDown from "@/components/navComeDown/SuComeDown";
import MaxComeDown from "@/components/navComeDown/MaxComeDown";
import PhotoComeDown from "@/components/navComeDown/PhotoComeDown";
import MaterialComeDown from "@/components/navComeDown/MaterialComeDown";
import PsComeDown from "@/components/navComeDown/PsComeDown";
import SchemeTextComeDown from '@/components/navComeDown/SchemeTextComeDown'
import ChartletComeDown from '@/components/navComeDown/ChartletComeDown';
export default {
  components: {
    Collapse,
    CourseComeDown,
    SuComeDown,
    MaxComeDown,
    PhotoComeDown,
    MaterialComeDown,
    PsComeDown,
    SchemeTextComeDown,
    ChartletComeDown,
  },
  props:['banner'],
  data() {
    return {
      imgVal: '',
      softwareList: [],
      params:{
        key: '',
        system: 1,
        page: 1
      },
			hotTags: [],
      defaultAvatar: '',
      routeList: [],
      isMyShow: false,
      timer: "",
      isCourseComeDown: false,
      isSuComeDown: false,
      isMaxComeDown:false,
      isPhotoComeDown: false,
      isMaterialComeDown: false,
      navHover: null,
    };
  },
  created() {
    this.routeList = this.$router.options.routes;
		this.getHot()
    latestAnnouncement().then((res) => {
      if (res.data) {
        if (localStorage.getItem("AnnouncementId") != res.data.id) {
          this.$placardPop().show(res.data);
        }
        //将公告id存入缓存
        localStorage.setItem("AnnouncementId", res.data.id);
      }
    });
    if (this.$route.query.inviteUid && !this.isLogin) {
      this.$login({ register: 1 });
    }
    if (this.$route.query.token) {
      if (this.$route.query.token != getToken()) {
        setToken(this.$route.query.token);
        this.$router.go(0);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if(this.banner.length>0)
      this.imgVal = this.banner[0].src
      // console.log(this.imgVal)
    })
  },
  methods: {
    // 搜索关键词跳转
    searchKey(){
      getList(this.params).then(res=>{
        if(res.status === '200'){
          const {href} = this.$router.resolve({ path: "/software/softwareList", query: {key: this.params.key} });
          window.open(href, '_blank')
          // this.$router.push({ path: "/software/softwareList", query: {key: this.params.key } });
        }
      })
    },
		getHot() {
			getRecommendHotWords({ fid: 1990})
				.then((res) => {
					this.hotTags = res.data
				})
		},
		searchTag(http) {
			window.open(http, '_blank')
		},
    // 跳转上传页面
    toUploadSoftware(){
      if(!this.isLogin){
        this.$login()
        return
      }
      let routeInfo = this.$router.resolve({ path: '/software/uploadSoftware'});
      window.open(routeInfo.href, '_blank');
    },
    goLogin() {
      this.$login({ isRouter: false });
    },
    removeToken() {
      removeToken();
      this.$router.go(0);
    },
  },
  computed: {
    activeMenu() {
      const route = this.$route;
      const { path, meta } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    ...mapGetters(["userInfo", "isLogin"]),
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  watch: {
    isLogin() {
      this.$router.go(0);
    },
  },
};
</script>

<style scoped>
	
.hot {
  width:5.2rem;
}
.hotList {
	display: flex;
	padding: 0.08rem 0;
}
.hotLi {
	font-size: 0.12rem;
	color: #ffffff;
	margin: 0 0.08rem;
}
.hotLi:nth-child(1) {
	margin: 0;
}
.hotLi:nth-child(2) {
	color: #f46600;
}
.searchBox{
  width:5.2rem;
  display: flex;
  align-items: center;
  border: 1px solid #dcdfe6;
  border-radius: 0.04rem;
  padding-right: 0.1rem;
  margin: auto;
  margin-top: 1.2rem;
  background:white;
}
.logoTitle{
  width: 1.11rem;
  height: 0.39rem;
	margin-right: 0.8rem;
}
.freewareLogo1 {
  width: 1.11rem;
  height: 0.39rem;
}
.NavUser:hover + .uploadList {
  visibility: visible;
  opacity: 1;
}

.uploadList:hover {
  visibility: visible;
  opacity: 1;
}

.uploadList {
  position: absolute;
  width: 1.68rem;
  /*height: 1.77rem;*/
  background: #ffffff;
  border-radius: 0.08rem;
  padding: 0.07rem 0;
  top: 0.4rem;
  left: -0.3rem;
  visibility: hidden;
  transition: 0.2s;
}

.uploadItem:hover {
  background: #f46600;
  color: #ffffff;
}

.uploadItem {
  color: #666666;
  font-size: 0.14rem;
  padding: 0.1rem 0 0.1rem 0.2rem;
  display: flex;
  align-items: center;
  /* transition: 0.2s; */
  cursor: pointer;
  position: relative;
}
.uploadItem img{
  position: absolute;
  height: 0.16rem;
  right: 0.05rem;
  top: 0.05rem;
}

.uploadItem i {
  font-size: 0.22rem;
}

.container{
  width:100%;
	background: rgba(0, 0, 0, 0.3);
  /*background-image: url("../../../assets/image/vip/banner.png");*/
  background-repeat: no-repeat;
  background-size: 100%;
}

.navP {
  position: sticky;
  top: 0;
  width: 14.4rem;
  height:4rem;
  margin: auto;
  z-index: 9;
}

.navStatus {
  height: 0.68rem;
}

.NavItem.active {
  /*color: #f46600;*/
}

.NavUserAvatar {
  width: 0.36rem;
  height: 00.36rem;
  overflow: hidden;
  border-radius: 50%;
  -webkit-box-shadow: inset 0 -1px 0 #3333sf;
  -webkit-transition: 0.4s;
  -webkit-transition: -webkit-transform 0.4s ease-out;
  transition: transform 0.4s ease-out;
  -moz-transition: -moz-transform 0.4s ease-out;
}

.NavUserAvatar:hover {
  box-shadow: 0 0 10px #fff rgba(255, 255, 255, 0.6),
    inset 0 0 20px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0 0 10px #fff rgba(255, 255, 255, 0.6),
    inset 0 0 20px rgba(255, 255, 255, 1);
  transform: rotateZ(360deg);
  -webkit-transform: rotateZ(360deg);
  -moz-transform: rotateZ(360deg);
}

.NavUserAvatar img {
  width: 100%;
}

.NavBoxLeft {
  display: flex;
  align-items: center;
  position: relative;
}

.NavUser img {
  width: 0.18rem;
  height: 00.18rem;
  margin-right: 0.3rem;
}

.NavUser {
  font-size: 0.16rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  position: relative;
}
.NavUser .newImg {
  position: absolute;
  height: 0.16rem;
  top: -0.15rem;
  right: -0.2rem;
  width: 0.22rem;
}

.NavUserBox {
  display: flex;
  align-items: center;
}

.NavList {
  display: flex;
}

.NavLogo img {
  width: 1rem;
  margin-top: 0.03rem;
}

.NavBox {
  width: 100%;
  min-width: 14rem;
  /*height: 0.68rem;*/
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0 0.12rem; */
  z-index: 9;
}

.NavBoxNull {
  width: 2.68rem;
  height: 0.68rem;
}
.NavBoxNull:hover .nullBox {
  visibility: visible;
}
.nullBox {
  visibility: hidden;
  position: relative;
}
.nullBox img {
  width: 0.71rem;
  height: 0.71rem;
  top: 0.35rem;
  position: absolute;
  right: 0.35rem;
}
.nullBox div {
  position: absolute;
  width: 1.38rem;
  height: 0.66rem;
  background: #ffffff;
  border-radius: 0.08rem;
  right: 0.2rem;
  top: 1rem;
  padding: 0.1rem 0;
  box-shadow: 0px 0px 6px rgba(51, 51, 51, 0.16);
}

.nullBox p {
  text-align: center;
  font-family: DIN;
  font-weight: 500;
  opacity: 1;
  color: #f46600;
}
.nullBox p:nth-child(1) {
  font-size: 0.18rem;
  margin-bottom: 0.04rem;
}

.nullBox p:nth-child(2) {
  font-size: 0.14rem;
}

.NavItem {
  color: #ffffff;
  line-height: 0.68rem;
  font-size: 0.16rem;
  position: relative;
  margin-right: 0.5rem;
	white-space: nowrap;
}
.NavItem img {
  position: absolute;
  height: 0.16rem;
  top: -0.15rem;
  right: -0.1rem;
}

.NavLogo:hover + .BookMark {
  display: block;
}

.BookMark:hover {
  display: block;
}

.BookMark {
  display: none;
  position: absolute;
  top: 0.14rem;
}

.BookMarkImg {
  position: absolute;
  left: 0.84rem;
}

.BookMarkImg img {
  width: 0.718rem;
  height: 0.718rem;
}

.BookMarkBox {
  position: absolute;
  top: 0.64rem;
  width: 2.5rem;
  height: 0.84rem;
  background: #ffffff;
  opacity: 1;
  border-radius: 0.8rem;
}

.BookMarkBox img {
  width: 0.44rem;
  height: 0.48rem;
  position: absolute;
  top: 0.18rem;
  left: 0.2rem;
}

.BookMarkBox p {
  position: absolute;
  top: 0.18rem;
  left: 0.74rem;
  /* width: 1.30rem; */
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: bold;
  /* line-height: 0px; */
  color: #040404;
  opacity: 1;
}

.BookMarkBox span {
  position: absolute;
  top: 0.46rem;
  left: 0.74rem;
  /* width: 0.98rem; */
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: 400;
  /* line-height: 0px; */
  color: #666666;
  opacity: 1;
}

.User {
  display: flex;
}
.User:hover > .My {
  transition: visibility 0.5s, opacity 0.5s;
  visibility: visible;
}
.My:hover {
  visibility: visible;
}

.My {
  visibility: hidden;
  background-image: url("../../../assets/image/navbar/my.png");
  position: absolute;
  width: 1.63rem;
  height: 2.07rem;
  opacity: 1;
  right: -0.5rem;
  /* top: 0.48rem; */
  margin-top: 0.54rem;
  background-size: cover;
  transition: 0.2s;
}

.ZhiBi {
  width: 1.23rem;
  height: 0.7rem;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(153, 153, 153, 0.15);
  opacity: 1;
  border-radius: 0.04rem;
  margin: 0.2rem;
}
.ZhiBi p {
  position: absolute;
  /* width: 0.56rem; */
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: bold;
  /* line-height: 0px; */
  color: #040404;
  opacity: 1;
  margin: 0.1rem 0.34rem 0 0.34rem;
}

.ZhiBi div {
  position: absolute;
  width: 0.63rem;
  height: 0.3rem;
  opacity: 1;
  margin: 0.35rem 0.3rem 0.05rem 0.3rem;
  display: flex;
}

.ZhiBi div p {
  /* width: 29px; */
  height: 0.17rem;
  font-size: 0.12rem;
  font-family: PingFang SC;
  font-weight: 400;
  /* line-height: 0px; */
  color: #666666;
  opacity: 1;
  margin: 0.07rem 0 0.06rem 0.34rem;
}

.MyHandle {
  border-top: 1px solid #f7f6fb;
  opacity: 1;
}
.MyHandle p {
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: bold;
  /* line-height: 0px; */
  color: #040404;
  opacity: 1;
  cursor: pointer;
}
.MyHandle p:first-child {
  margin: 0.16rem 0 0 0.53rem;
}

.MyHandle p:last-child {
  margin: 0.2rem 0 0 0.53rem;
}

.ComeDown {
  position: absolute;
  width: 100vw;
  left: 0;
}

.NavItem:hover {
  color: #f46600;
}
.el-popper /deep/ .popper__arrow {
  border-bottom-color: #1ebef4 !important;
  left: 50% !important;
  visibility: hidden;
}

.el-dropdown-menu {
  position: fixed !important;
  top: 0.5rem !important;
  left: 0 !important;
  width: 100%;
  min-width: 14rem;
}
.rightBox:hover {
  transform: scale(1.2);
}
/deep/ .el-input__inner{
  border: none;
}
</style>
