<template>
  <div style="width: 100%;background:white;min-width: 1440px">
    <!--侧栏-->
    <helper></helper>
<!--    <div class="rightSide">-->
<!--      <div>-->
<!--        <img src="../../assets/image/freeSoftware/farSet.png" alt="">-->
<!--        <div>远程安装</div>-->
<!--      </div>-->
<!--      <div>-->
<!--        <img src="../../assets/image/freeSoftware/farOrder.png" alt="">-->
<!--        <div>远程订单</div>-->
<!--      </div>-->
<!--    </div>-->
    <!--顶部导航栏-->
    <Navbar1 :banner="banner" v-if="banner.length > 0"></Navbar1>
    <div class="containerBox">
    <!--周排行榜-->
    <div class="weekOrderContainer">
      <div class="weekOrderTitle">本周排行榜</div>
      <div class="weekOrderBox">
        <div v-for="item in weekRankingList" :key="item.id"  style="cursor:pointer" @click="toDetail(item.pid)">
          <img class="order123" src="../../assets/image/freeSoftware/order1.png" v-if="Number(item.id) + 1 === 1"/>
          <img class="order123" src="../../assets/image/freeSoftware/order2.png" v-if="Number(item.id) + 1 === 2"/>
          <img class="order123" src="../../assets/image/freeSoftware/order3.png" v-if="Number(item.id) + 1 === 3"/>
          <div class="order" v-if="Number(item.id) + 1 > 3">{{Number(item.id) + 1}}</div>
          <img :src="item.img" alt="" class="logo">
          <div class="desc">{{item.title}}</div>
        </div>
      </div>
    </div>

    <!--常用软件-->
    <div class="commonSoftwareContainer">
      <div class="commonSoftwareTitle">常用软件</div>
      <div class="commonSoftwareBox">
        <div v-for="item in commonSoftwareList" :key="item.id"  style="cursor:pointer" @click="toList(item.id)">
          <img :src="item.img" alt="" class="logo">
          <div class="desc">{{item.title}}</div>
        </div>
      </div>
    </div>

    <!--软件分类导航-->
    <div class="softwareTypeContainer">
      <div class="softwareTypeTitle">软件分类导航</div>
      <div class="softwareTypeBox">
        <div class="headTitle">
          <div v-for="(item,index) in softwareTypeList" :key="index" :style="{color:index===choosed ? 'black' : 'rgba(153, 153, 153, 1)'}" @click="change(index)">
            {{item.title}}
            <div :class="{active:choosed === index}"></div>
          </div>
        </div>
        <div class="headBox">
          <div :id="'tab'+index" v-for="(item,index) in softwareTypeList"  :key="index">
            <div class="headBoxLeftBox">
              <div>
                <img :src="item.icon" alt="">
                <div>{{item.title}}</div>
              </div>
            </div>
            <div class="headBoxRightBox">
              <div v-for="(iitem,iindex) in item.child" :key="iindex" style="cursor:pointer" @click="toList(iitem.id)">
                <img :src="iitem.icon" alt="">
                <div>{{iitem.title}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { getCommonSoftware,getWeekRanking,getAllCate, getBanner} from "../../Api";
import Navbar1 from "./componts/Navbar1";
import Helper from "./componts/helper"
import {
  Footer
} from "../../layout/componts";
import {mapGetters} from "vuex";
export default {
  name: "SoftwareIndex",
  components: {
    Navbar1,
    Footer,
    Helper
  },
  computed: {
    ...mapGetters(["isLogin"]),
  },
  mounted() {
    // 监听滚动事件
    // window.addEventListener("scroll", this.onScroll, false);
    getCommonSoftware().then(res=>{
      if(res.status === '200'){
        this.commonSoftwareList = res.data
      }
    })
    getAllCate().then(res=>{
      this.softwareTypeList = res.data
    })
    getWeekRanking().then(res=>{
      this.weekRankingList = res.data
    })
    getBanner({position:2066,cate:2067}).then(res=>{
      this.banner = res.data
    })
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    // window.removeEventListener("scroll", this.onScroll);
  },
  data() {
    return {
      choosed: 0,
      softwareTypeList:[],
      commonSoftwareList:[],
      weekRankingList:[],
      banner: [],
    }
  },
  created() {
  },
  methods: {
    toDetail(id) {
      console.log(id)
      if (!this.isLogin) {
        this.$login()
        return
      }
      // this.$router.push({path: "/software/softwareDetail", query: {id: id}});
      const {href} = this.$router.resolve({ path: "/software/softwareDetail", query: {id: id} });
      window.open(href, '_blank')
    },
    toList(id){
      const {href} = this.$router.resolve({ path: "/software/softwareList", query: {child:id } });
      window.open(href, '_blank')
      // this.$router.push({ path: "/software/softwareList", query: {child:id } });
    },
    // 滚动监听器
    // onScroll() {
    //   // 获取所有锚点元素
    //   const navContents = document.querySelectorAll(".headBox>div");
    //   // 所有锚点元素的 offsetTop
    //   const offsetTopArr = [];
    //   navContents.forEach((item) => {
    //     offsetTopArr.push(item.offsetTop);
    //   });
    //   // 获取当前文档流的 scrollTop
    //   const scrollTop =
    //       document.documentElement.scrollTop || document.body.scrollTop;
    //   // 定义当前点亮的导航下标
    //   let navIndex = 0;
    //   for (let n = 0; n < offsetTopArr.length; n++) {
    //     // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
    //     // 那么此时导航索引就应该是n了
    //     if (scrollTop >= offsetTopArr[n]) {
    //       navIndex = n;
    //     }
    //
    //     if (
    //         scrollTop + document.documentElement.clientHeight ===
    //         document.documentElement.scrollHeight
    //     ) {
    //       navIndex = offsetTopArr.length - 1;
    //     }
    //   }
    //   console.log(navIndex)
    //   this.choosed = navIndex;
    // },
    change(index){
      this.choosed = index
      window.scrollTo({
        top: this.$el.querySelector("#tab" + index).offsetTop - 80,
          behavior: "smooth"
    })
      // document.querySelector("#tab"+index).scrollIntoView({
      //   behavior: "smooth"
      // });
    }
  }
};
</script>
<style scoped>

/*.rightSide{*/
/*  position: fixed;*/
/*  right:30px;*/
/*  top:40%;*/
/*  height: 24px;*/
/*  color: rgba(56, 56, 56, 1);*/
/*  font-size: 16px;*/
/*  font-weight: 500;*/
/*  z-index: 10;*/
/*}*/
/*.rightSide>div{*/
/*  margin-bottom:12px;*/
/*  text-align: center;*/
/*  cursor: pointer;*/
/*  width: 100px;*/
/*  height: 118px;*/
/*  padding: 12px;*/
/*  background: rgba(255, 255, 255, 1);*/
/*  box-shadow: 0px 0px 4px rgba(238, 148, 84, 0.25);*/
/*  border-radius: 8px;*/
/*}*/
 .containerBox{
   width:14.4rem;
   margin: auto;
   margin-bottom: 0.2rem;
 }
 .softwareTypeContainer{
   width:100%;
   margin-top: 0.52rem;
 }
 .softwareTypeTitle{
   height: 0.41rem;
   color: rgba(0, 0, 0, 100);
   font-size: 0.28rem;
   font-weight: 400;
 }
.softwareTypeBox{
  width:100%;
}
.headTitle{
  margin-top:0.1rem;
  height: 0.8rem;
  line-height: 0.8rem;
  width:100%;
  display: flex;
  position: sticky;
  top: 0px;
  background: white;
  z-index: 9;
  padding-top:0.12rem
}
.headTitle>div{
  /*margin: auto;*/
  margin-right:0.4rem;
  /*height:30px;*/
  line-height: 0.3rem;
  font-size: 0.18rem;
  cursor: pointer;
}

.headBox{
  width:100%;
  background: rgba(252, 252, 252, 0.9);
  border: 1px solid rgba(238, 238, 238, 1);
  border-radius: 0.16rem;
}
.headBox>div{
  display: flex;
  justify-content: space-between;
  /*height: 400px;*/
  /*align-items: center;*/
}
.headBoxLeftBox{
  width:2rem;
  border: 1px solid rgba(238, 238, 238, 1);
  box-sizing: border-box;
  text-align: center;
  position: relative;
  padding: 0.5rem;
  border-radius: 0.08rem;
  background: rgba(255, 255, 255, 1);
}
 .headBoxLeftBox>div{
   position: absolute;
   margin:auto;
   top: 50%;
   left: 50%;
   transform:translate(-50%,-50%); /*针对元素本身向左以及向上移动50%*/
 }
.headBoxLeftBox>div>img{
  width:0.5rem;
  height:0.5rem;
  border-radius:50%
}
.headBoxRightBox{
  width:12.4rem;
  display: flex;
  /*border-bottom-right-radius: 8px;*/
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  background: rgba(245,246,247, 0.6);
  flex-wrap:wrap;
}
.headBox>div:last-child>.headBoxRightBox{
  border-bottom: none;
}
/*.headBoxRightBox:last-child{*/
/*  border-bottom: 1px solid rgba(238, 238, 238, 1);*/
/*}*/
.headBoxRightBox>div{
  width: 2.6rem;
  height: 0.5rem;
  background: rgba(255, 255, 255, 1);
  border-radius: 0.08rem;
  margin: 0.2rem;
  display: flex;
  align-items: center;
}
 .headBoxRightBox>div>img{
   width:0.4rem;
   height:0.4rem;
   border-radius:50%;
   margin-right: 0.06rem;
 }
.active{
  border-bottom: 3px solid rgba(244, 102, 0, 1);
  color: black !important;
  width: 50%;
  margin: auto
}
 .weekOrderContainer{
   width:100%;
   margin-top: 0.32rem;
   margin-bottom:0.32rem
 }
 .weekOrderTitle{
   height: 0.41rem;
   color: rgba(0, 0, 0, 100);
   font-size: 0.28rem;
   font-weight: 400;
   margin-bottom:0.1rem
 }
 .weekOrderBox{
   width:100%;
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
   padding: 0.08rem;
 }
 .weekOrderBox .order{
   width: 0.22rem;
   height: 0.22rem;
   margin-right: 0.06rem;
   text-align: center;
 }
 .weekOrderBox .order123{
   width: 0.22rem;
   height: 0.22rem;
   margin-right: 0.06rem;
   margin-top: 0.09rem
}
 .weekOrderBox .logo{
    width:0.4rem;
    height: 0.4rem;
   border-radius: 50%;
   margin-right: 0.06rem
 }
 .weekOrderBox .desc{
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;

 }
 .weekOrderBox>div{
   width:30%;
   height:0.4rem;
   margin: 0.1rem;
   line-height: 0.4rem;
   display: flex;
 }

 .commonSoftwareContainer{
   width:100%;
   margin-top: 0.52rem
 }
 .commonSoftwareTitle{
   height: 0.41rem;
   color: rgba(0, 0, 0, 100);
   font-size: 0.28rem;
   font-weight: 400;
   margin-bottom:0.1rem
 }
 .commonSoftwareBox{
   width:100%;
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
 }
 .commonSoftwareBox>div{
   width:19%;
   height:0.92rem;
   background: orange;
   margin-top: 0.1rem;
   line-height: 0.92rem;
   background: rgba(255, 255, 255, 1);
   box-shadow: 0 0 10px 1px rgba(179, 179, 179, 0.15);
   border-radius: 0.08rem;
   display: flex;
   align-items: center;
   padding: 0.08rem;
 }
 .commonSoftwareBox .logo{
   width:0.6rem;
   height: 0.6rem;
   border-radius: 50%;
   margin-right: 0.06rem
 }
 .commonSoftwareBox .desc{
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
 }
</style>